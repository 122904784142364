import { useEffect, useState } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import { toast } from 'react-hot-toast';
import { TypeAnimation } from 'react-type-animation';
import Button from '../components/Button';
import Text from '../components/Text';
import { askAITarot, translateToEnglish, translateToKorean } from '../api';
import { supabase } from '../utils/supabase';
import { SubText } from './Ready';

const Input = styled.textarea`
  border: none;
  width: 100%;
  min-height: 16.7rem;
  padding: 1rem;
  border: 1px solid #222529;
  border-radius: 0.4rem;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.1rem;
  background: none;
  letter-spacing: 0.15px;
  color: #222529;

  &::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.1rem;
    letter-spacing: 0.15px;
    color: #888d96;
  }

  &:focus {
    outline: none;
  }
`;

const InputWorryPage = ({ value, onChange, onReady, setAnswer }) => {
  const [submitStarted, setSubmitStarted] = useState(false);
  const [registered, setRegistsered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const utmParams = new URLSearchParams(window.location.search);

  const userRegisterHandler = async () => {
    if (!utmParams.has('phone')) {
      toast.error('등록 중 오류가 발생했습니다.');
      return;
    }

    amplitude.getInstance().logEvent('submit_worry', { referral: 'worry_again_ai' });

    const phone = utmParams.get('phone');

    if (registered) return;

    try {
      setIsLoading(prev => true);

      const askedResponse = await askAITarot(value);

      setAnswer(askedResponse.data.choices[0].text);
      setIsLoading(prev => false);
      onReady();

      const { data: userData, error: userDataError } = await supabase
        .from('experiment_230103')
        .insert({
          phone,
          worry: value,
          answer: askedResponse.data.choices[0].text,
        })
        .select('*');
    } catch (error) {
      toast.error('고민의 길이를 조금만 줄여주세요.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    amplitude.getInstance().logEvent('view_main', { referral: 'worry_again_ai' });
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <TypeAnimation
            style={{
              marginTop: '8rem',
              fontFamily: 'OK GUNG',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '2.4rem',
              lineHeight: '180%',
              color: '#2e1717',
            }}
            sequence={['타로 리딩 중...(약 1분)']}
          />
          <SubText style={{ marginTop: '1.6rem', textAlign: 'center' }}>
            고민 내용에 따라
            <br /> 1분 이상 소요될 수 있습니다.
          </SubText>
        </>
      ) : (
        <>
          <Text style={{ width: '100%', margin: '1.6rem 0' }}>
            지금의 고민을 이야기해주세요.
            <br />
            {value.length === 0 && <span style={{ color: '#E1354C' }}>30자 이상 부탁드려요</span>}
            {value.length <= 30 && value.length !== 0 && value.length !== 30 && (
              <span style={{ color: '#E1354C' }}>{`${30 - value.length}자 더 부탁드려요`}</span>
            )}
          </Text>
          <Input placeholder={''} value={value} onChange={onChange} />
          <div
            style={{
              marginTop: '1.6rem',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            <Button
              text="입력 완료"
              width="50%"
              color="#fff"
              backgroundColor={value.length >= 30 ? '#222529' : '#CFD4DA'}
              style={{
                fontFamily: 'Roboto',
                fontSize: '2rem',
                fontWeight: 700,
                lineHeight: '6.4rem',
              }}
              onClickHandler={
                value.length >= 30
                  ? userRegisterHandler
                  : () => {
                      toast.error('고민을 30자 이상 입력해주세요.');
                    }
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default InputWorryPage;
