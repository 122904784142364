import styled from 'styled-components';
import './loadAmplitude';
import { useState, useEffect } from 'react';
import amplitude from 'amplitude-js';
import Header from './components/Header';
import devider from './assets/border.png';
import './App.css';
import InputWorryPage from './pages/InputWorryPage';
import Ready from './pages/Ready';
import Text from './components/Text';

const Devider = styled.img`
  width: 32rem;
  height: 1.07rem;
`;

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    const phone = urlParams.get('phone');

    if (!phone) {
      setIsError(true);
      return;
    }

    const identify = new amplitude.Identify().setOnce('phone', phone);
    amplitude.getInstance().identify(identify);
  }, []);
  const [pageNum, setPageNum] = useState(0);
  const buttonClickHandler = () => {
    setPageNum(prev => prev + 1);
  };

  const [inputWorry, setInputWorry] = useState('');
  const [answer, setAnswer] = useState('');

  const inputWorryChangeHandler = event => {
    setInputWorry(prev => event.target.value);
  };

  return (
    <>
      <Header />
      <div
        style={{
          position: 'relative',
          padding: '0 2rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Devider src={devider} />

        {isError && (
          <Text style={{ marginTop: '3rem', textAlign: 'center' }}>
            오류가 발생했습니다.
            <br />
            고객센터로 문의해주세요.
          </Text>
        )}

        {!isError && pageNum === 0 && (
          <InputWorryPage
            value={inputWorry}
            onChange={inputWorryChangeHandler}
            onReady={buttonClickHandler}
            setAnswer={setAnswer}
          />
        )}
        {!isError && pageNum === 1 && <Ready answer={answer} />}
      </div>
    </>
  );
}

export default App;
