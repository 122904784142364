import { useEffect, useState } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import StarRatings from 'react-star-ratings';
import { toast } from 'react-hot-toast';
import Text from '../components/Text';
import Button from '../components/Button';
import { supabase } from '../utils/supabase';

const Ready = ({ answer }) => {
  const [rating, setRating] = useState(0);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    amplitude.getInstance().logEvent('view_result', { referral: 'worry_again_ai' });
  }, []);

  const utmParams = new URLSearchParams(window.location.search);
  const phone = utmParams.get('phone');

  const feedbackInputChangeHandler = event => {
    setFeedback(prev => event.target.value);
  };

  const onClickSubmit = async () => {
    try {
      setIsLoading(prev => true);
      const { data, error } = await supabase
        .from('experiment_230103')
        .select('id')
        .eq('phone', phone)
        .order('id', { ascending: false })
        .limit(1)
        .single();

      if (error) {
        return;
      }

      if (data.id) {
        const { data: dataUpdated, error } = await supabase
          .from('experiment_230103')
          .update({ rating, feedback })
          .eq('id', data.id)
          .select('*');
        console.log(dataUpdated);
      }

      amplitude.getInstance().logEvent('submit_feedback', { referral: 'worry_again_ai' });
      setIsSubmited(true);
    } catch (e) {
      toast.error('제출 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const ratingChangeHandler = newRating => {
    setRating(newRating);
  };

  return !isSubmited ? (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <SubText style={{ marginTop: '1.6rem', fontWeight: 700, fontSize: '2.5rem', textAlign: 'center' }}>
        타로 리딩 결과
      </SubText>
      <SubText style={{ marginBottom: '3rem' }}>
        <br />
        {answer.trim()}
        <br />
      </SubText>
      <SubText
        style={{ marginTop: '1.6rem', fontWeight: 700, fontSize: '1.2rem', textAlign: 'center', color: '#E1354C' }}>
        타로 리딩은 결정에 참고만 해주세요. 리딩 결과를 그대로 이행한 뒤 발생한 결과에 대해서는 책임지지 않습니다.
      </SubText>
      <SubText style={{ marginTop: '1.6rem', fontWeight: 700, fontSize: '1.6rem', textAlign: 'center' }}>
        이 리딩이 고민을 해결하시는 데 얼마나 도움됐나요?
        <br /> 5점 만점으로 평가해주세요.
      </SubText>
      <div style={{ marginTop: '1.6rem', width: '100%', display: 'flex', justifyContent: 'center' }}>
        <StarRatings
          rating={rating}
          changeRating={ratingChangeHandler}
          starRatedColor={'rgb(248,212,86)'}
          starHoverColor={'rgb(248,212,86)'}
        />
      </div>
      {rating > 0 && (
        <>
          <SubText style={{ marginTop: '1.6rem', fontWeight: 700, fontSize: '1.6rem', textAlign: 'center' }}>
            {rating}점을 주신 이유가 궁금해요 (선택)
          </SubText>
          <Input
            placeholder="어떤 점이 만족스러우셨나요? 혹은 만족스럽지 않으셨나요?"
            value={feedback}
            onChange={feedbackInputChangeHandler}
          />
        </>
      )}
      {isLoading && (
        <SubText style={{ marginTop: '1.6rem', fontWeight: 700, fontSize: '1.6rem', textAlign: 'center' }}>
          제출 하는 중...
        </SubText>
      )}
      <Button
        text="제출하기"
        width="100%"
        color="#fff"
        backgroundColor={'#222529'}
        style={{
          fontFamily: 'Roboto',
          fontSize: '2rem',
          fontWeight: 700,
          lineHeight: '6.4rem',
          marginTop: '1.6rem',
          marginBottom: '5rem',
        }}
        onClickHandler={
          rating === 0
            ? () => {
                toast.error('별점을 입력해주세요.');
              }
            : onClickSubmit
        }
      />
    </div>
  ) : (
    <Text style={{ marginTop: '5rem' }}>이용해주셔서 감사합니다.</Text>
  );
};

export const SubText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 180%;
  white-space: pre-wrap;
  color: #2e1717;
`;

export default Ready;

const Input = styled.textarea`
  border: none;
  width: 100%;
  min-height: 16.7rem;
  padding: 1rem;
  border: 1px solid #222529;
  border-radius: 0.4rem;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.1rem;
  background: none;
  letter-spacing: 0.15px;
  color: #222529;

  &::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.1rem;
    letter-spacing: 0.15px;
    color: #888d96;
  }

  &:focus {
    outline: none;
  }
`;
