const Button = ({ text, onClickHandler, width, backgroundColor, color, style, border }) => (
  <button
    onClick={onClickHandler}
    style={{
      ...style,
      color,
      width,
      backgroundColor,
      height: '6.4rem',
      lineHeight: '6.4rem',
      borderRadius: '.8rem',
      boxShadow: '0 .4rem .4rem rgba(0,0,0,0.1)',
      border: border || 'none',
    }}>
    {text}
  </button>
);

export default Button;
