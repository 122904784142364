import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/fonts.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Toaster } from 'react-hot-toast';
import GlobalStyles from './styles/GlobalStyles';
import App from './App';

// Sentry.init({
//   dsn: 'https://4cefc2be4c0547be8a5bf4db0fbc8eff@o4503932173418496.ingest.sentry.io/4503969169670144',
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Toaster position="top-center" />
    <GlobalStyles />
    <App />
  </>
);
